import api from "@/api";
import BreadCrumbs from "@/components/BreadCrumbs";
import { DefaultSetting, MetaRow } from "@/models/type";
import { sharer } from "@/utils";
import { setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import './index.scss'

export default class WorkDetail extends Vue {

  public workObj: SmsGoodWork = {}
  public newArr = [
    {
      imgPath: '/img/work/1.jpg',
      title: '民間龍鳳字',
      id: '27',
      introduce: '龍鳳花鳥文字是民間藝人利用花卉和禽鳥的圖案拼寫成漢字，近看細節是一些花鳥畫，遠看整體卻是一幅字，這種字畫結合的藝術形式被稱作花鳥字，是一種多彩花鳥蟲魚組合書法。導師教授不同的技巧，參加者根據自己創意進行龍鳳字作畫，在文字上添加屬於自己獨一無二的圖案。'
    },
    {
      imgPath: '/img/work/2.jpg',
      title: '中國傳統瓷畫',
      id: '28',
      introduce: '「廣彩」是「廣州織金彩瓷」的簡稱，源自廣州， 是中國四大名瓷之一，傳統中國彩瓷，以色彩鮮艷、構圖嚴謹、繪工精細而著名。導師教授中國彩瓷技巧，參加者學習起稿畫線、上色等繪圖技巧，在瓷碟創作作品。'
    },
    {
      imgPath: '/img/work/3.jpg',
      title: '中國面譜',
      id: '29',
      introduce: '中國面譜出現在傳統的戲曲中，不同的面譜造型，藉著線條和色彩，只需略施粉墨就能表示淨丑兩行所扮演的各種人物，色彩和圖案愈豐富，顯示各種不同人物性格的區分也越加鮮明，從而創造出許多歷史和神話人物的面譜，具有極大的欣賞和藝術價值。'
    },
    {
      imgPath: '/img/work/4.jpg',
      title: '立體打印模型-炮仗',
      id: '30',
      introduce: '炮竹又稱爆竹、爆仗、炮仗等，因常做成長鞭狀，故又稱鞭炮，爆竹最開始主要用於驅魔避邪，結合時下新穎的立體打印筆（簡稱3D筆），參加者學習使用3D筆的技巧，繪製與中華文化相關的作品，過程中可發揮個人創意及啟發自我創作力，從而獲得成就感。'
    },
    {
      imgPath: '/img/work/5.jpg',
      title: '擴香石-舞龍、醒獅',
      id: '31',
      introduce: '自古以來，龍是中國皇室和民間的崇拜對象和文化圖騰，不但是中國皇權的象徵，同時中國人亦自稱為「龍的傳人」。舞龍成為傳統節慶中不可或缺的活動，不少香港新界的宗族團體都以舞龍來慶祝節日、慶典或神誕活動。結合時下新穎的擴香石，參加者親自選擇精油香味，學習如何製作與中華文化相關的擴香石作品，學習調漿、倒模、脫模、上色及組合等技巧。'
    },
    {
      imgPath: '/img/work/6.jpg',
      title: '麵塑（兔仔）',
      id: '32',
      introduce: '麵塑，俗稱麵花、麵羊、禮饃、羊羔饃、花饃、捏麵人等，以麵粉為主料，調成不同色彩，捏麵藝人用手加上簡單工具，根據所需隨手取材，是中國民間傳統藝術之一，最早在漢代就有文字記載，宜賓麵塑則起於清末民初，至今已有一百多年的歷史。捏、搓、揉、掀等的技巧，用小竹刀或牙籤點、切、刻、畫等形式刻畫十二生肖之兔年公仔，令青少年在樂趣中了解非物質文化遺產與十二生肖的典故。'
    },
    {
      imgPath: '/img/work/11.jpg',
      title: '紮染',
      id: '33',
      introduce: '紮染，古稱扎纈、絞纈、絞染、紮染等，是一種染布工藝。 織物在染色時部分結紮起來使之不能著色的一種染色方法，屬防染工藝，也是中國傳統的手工染色技術之一。導師指導紮染技藝，體驗中國傳統文化，製作獨一無二的作品紮染作品，過程中了解這兩項傳統工藝的獨特之處。'
    },
    {
      imgPath: '/img/work/7.jpg',
      title: '客家茶粿',
      id: '34',
      introduce: '茶粿或稱茶粄是中國客家文化中的傳統食物，主要是以糯米所製成的各種糕點，分為鹹、甜兩種。據說茶粿起源於客家人喜歡圍在一起聊天，於是以糯米製作成甜鹹小點，並配以茶水，邊吃邊喝邊談，故命名為「茶粿」。在導師的指導下參加者親手製作四個不同動物造型的茶粿，過程中啟發自我創作力，從而獲得成就感。'
    },
    {
      imgPath: '/img/work/8.jpg',
      title: '印章篆刻',
      id: '35',
      introduce: '篆刻是一種傳統的藝術形式，因古代印章多采用篆書入印而得名。它是書法和鐫刻（包括鑿、鑄）結合，來製作印章的藝術。中國的篆刻印章藝術常被稱作「篆刻」，因一般都以篆書書體並結合刻刀各種刀法完成，是書法結合雕刻的一門藝術。在過程中學習磨章及篆文起稿，處理執刀運刀基礎技巧，印章石材知識，了解傳統工藝的獨特之處。'
    },
    {
      imgPath: '/img/work/9.jpg',
      title: '水仙花氣球',
      id: '36',
      introduce: '水仙為中國十大名花之一，民間的清供佳品，每過新年，人們都喜歡清供水仙，點綴做為年花。水仙清麗脫俗，高貴雅致，深得中國人的喜愛，屬於歲首清供的必備花卉，亦常是繪畫祝壽吉祥題材，歷代將水仙視為珍貴的貢品。扭氣球作為一種新興的表演藝術，將與傳統文化作結合。參加者利用氣球扭出一盆傳統的年花--水仙，為農曆新年增添喜慶的氣氛。'
    },
    {
      imgPath: '/img/work/10.jpg',
      title: '手雕麻雀',
      id: '37',
      introduce: '麻雀（又名麻將）被譽為中國的國粹，麻雀上的圖案在未有機雕前，全憑手工雕製，一副好的麻雀離不開精湛的手工。由1960至80年代，是手製麻雀的全盛期，從業員數百人，製作坊近百間。參加者在過程中了解手雕麻雀這項冷門手藝如何與雕刻技術結合，傳承獨有的文化，雕刻獨一無二的作品'
    },
    {
      imgPath: '/img/work/12.jpg',
      title: '中式糖畫',
      id: '38',
      introduce: '糖畫是一種中國傳統民間藝術，是國家級非物質文化遺產。起源於明代的「糖丞相」，主要以紅糖、白糖加上少許飴糖（麥芽糖），放在溫火上熬煮，直到牽絲時即可用於石板上畫出圖案或文字。參加者在過程中發揮創意，製作出獨一無二的糖畫作品，享受這種傳統手藝的樂趣。'
    },

]

  metaList: Array<MetaRow> = []

  meta = setup(() => {
    const { meta } = useMeta({
      meta: this.metaList
    })
    return meta
  })

  getDetail(): void {
    const id = this.$route.params.id as never
    api.getWorkById(id).then(res => {
      this.workObj = res.data
      this.getMeta()
    })
  }

  getMeta(): void {
    this.metaList.splice(0, this.metaList.length)
    this.metaList.push(
      {property: 'og:title', content: this.workObj.title as string},
      {property: 'og:description', content: this.workObj.introduce as string},
      {property: 'og:image', content: this.workObj.imgPath as string}
    )
  }

  render(): JSX.Element {
    return <div class="work_detail">
      <BreadCrumbs style="margin-bottom: 20px" />
      <div class="work_detail-header">
        <div class="work_detail-header-left">{this.workObj.title}</div>
        <div class="work_detail-header-right">{this.workObj.author}</div>
      </div>
      <p class="work_detail-introduce">{ this.workObj.introduce }</p>
      <img src={this.workObj.imgPath} alt={this.workObj.title} />
      {
        this.workObj.title
          && <div class="work_detail-fun">
            <share-network
              network="facebook"
              url={window.location.href}
              title={this.workObj.title}
              description={this.workObj.introduce}
            >
              <div class="facebook"/>
            </share-network>
          <div class="wechat" onClick={() => sharer(2)}/>
            <share-network
              network="whatsapp"
              url={window.location.href}
              title={this.workObj.title}
              description={this.workObj.introduce}
            >
              <div class="phone"/>
            </share-network>
        </div>
      }
    </div>
  }
  mounted(): void {
    // this.getDetail()
    const id = this.$route.params.id
    const index = this.newArr.findIndex(item => item?.id == id)
    index == -1 ? '' : this.workObj = this.newArr[index]
  }
}

/**
 * @description 优秀作品
 * @author Jinx
 * @date 2021-08-27 15:03:41
 * @export
 * @interface SmsGoodWork
 * @extends {DefaultSetting}
 */
 export interface SmsGoodWork extends DefaultSetting {
  author?: string
  imgPath?: string
  introduce?: string
  title?: string
  type?: number
}
